* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
} 

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo{
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  /* background-color: #1890ff; */
}

.site-layout .site-layout-background {
  background: #fff;
}

.main-content {
  padding: 0 23px;
  margin-top: 30px;
  z-index: 0;
}

.ant-layout-content {
  overflow-x: auto !important;
}

.loginContainer{
  background-color: #1890ff;
  margin-top: 30px;
  width: 100%;
  max-width: 500px;
}


.ant-form-item-control-input-content{
  display: flex;
  width: 100%;
  align-items: center !important;
  justify-content: space-between;
}

.logoText{
  color: "#4b7f9c"; font-size: 20px;margin:0px !important 
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  min-height: 100px
}

figure img{
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form p img{
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.ant-col {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.workflow{
  display: flex;
  align-items: center;
}